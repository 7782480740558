import * as React from "react";
import HcpLayout from "../components/layout";
import { Seo } from "../components/seo";
import FluidContent from "../components/fluidContent";

const PrivacyPolicyPage = () => {
    return (
        <HcpLayout pageClass="privacy-policy-page">
            <FluidContent className='p-60'>
                <div className="centered-content">
                <div className="content-wrap">
                        <h1>Privacy Policy</h1>
                        <p>Artbio Inc. ("ARTBIO") respects the privacy of visitors ("you," "data subject(s)") to its website and is committed to the protection of their personal information. This privacy statement explains data collection and use practices of ARTBIO’S website. ARTBIO is the data controller of the personal data we have registered and are processing in connection with your use of our website services.</p>

                        <p>Description of the information ARTBIO may collect on this website and for which purposes:</p>

                        <p className="sub-head"><strong>Personal Data</strong></p>
                        <p>ARTBIO collects personal data (ie, information by which you can be identified, such as name, address, telephone number, or email address) only when it is asked for and/or you voluntarily submit it to us on this website. We may use the information to respond to your requests; improve our level of service and the content of our website; provide you with tips, helpful information, product news, and updates; notify you of new ARTBIO products and services; and for our own internal purposes.</p>
                        
                        <p className="sub-head"><strong>Non-Personal Data</strong></p>
                        <p>ARTBIO also collects non-personal data in aggregate form to track data such as the total number of visits to our website, the number of visitors to each page of our website, and the domain names of our visitors' internet service providers. We use this information, which remains in aggregate, non-personally identifiable form, to understand how our visitors use our website so that we may improve our website and the services we offer.</p>

                        <p className="sub-head"><strong>Use of IP Addresses</strong></p>
                        <p>An Internet Protocol (IP) address is a set of numbers that is automatically assigned to your computer whenever you log on to your internet service provider or through your organization’s local area network (LAN) or wide area network (WAN). Web servers automatically identify your computer by the IP address assigned to it during your session online.</p>

                        <p>ARTBIO or third-party companies acting on behalf of ARTBIO may collect IP addresses for the purposes of systems administration and to audit the use of our website. We do not ordinarily link a user's IP address to personally identifiable information of that user, which means each user's session will be logged, but the user remains anonymous to us. However, we may use IP addresses to identify users of our website when we feel it is necessary to enforce compliance with the website's terms of use, or to protect our service, website, or other users.</p>

                        <p className="sub-head"><strong>Cookies</strong></p>
                        <p>This website uses a technology called "cookies." A cookie is a small text file that is placed on your hard disk by a web page server. Cookies help provide additional functionality to the website or help us analyze usage of the website more accurately. For instance, our server may set a cookie that keeps you from having to enter a password more than once during a visit to the website. In all cases in which cookies are used, we will not collect personally identifiable information without your explicit permission. The only personally identifiable information ARTBIO collects is the information you voluntarily provide.</p>

                        <p>When you visit our website for the first time, our cookie banner will provide you with options for accepting or rejecting cookies. By accepting, you give a consent to the use of cookies. You can control and disable use of cookies by adjusting the settings on your browser. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies, or receive a warning before a cookie is stored, if you prefer. Please refer to your internet browser’s instructions or help screen to learn more about these functions. If you choose to decline cookies, you may not be able to fully experience the interactive features of this website or any other websites that you visit.</p>

                        <p className="sub-head"><strong>Sharing Personally Identifiable Information with Third Parties</strong></p>
                        <p>ARTBIO will not sell or rent personally identifiable information to any third party for any purpose. Sometimes selected third parties are used by ARTBIO to provide us with support services in connection with our website and such parties may, from time to time, have access to your information to enable them to provide those services to ARTBIO. Please rest assured that all companies providing such support services are required by ARTBIO to meet certain standards of data protection and are prohibited from using the information for their own marketing purposes.</p>

                        <p className="sub-head"><strong>Links to Other Websites</strong></p>
                        <p>This website may provide links to other ARTBIO and third-party websites as a service to our users. The privacy statement described here does not apply to those websites. Please ensure you check the legal and privacy statement posted on each website you visit.</p>

                        <p className="sub-head"><strong>Your rights</strong></p>
                        <p>Under applicable data protection laws, data subjects have numerous rights related to the personal data. Depending on the circumstances, you have or may have the right to:</p>
                        <ul>
                            <li>Withdraw any consent you have provided as a basis for processing your personal data. This will not affect the legality of the processing which occurred when the consent was valid and in effect</li>
                            <li>Request access to the processed personal data</li>
                            <li>Demand rectification of inaccurate personal data</li>
                            <li>Request the erasure, or restriction of, the processing of your personal data. However, please note that certain personal data is strictly necessary in order to achieve the purposes defined in this privacy policy and may also be required to be retained by applicable laws. Such data may not be deleted on your demand, without alterations to the underlying legal basis for the processing</li>
                            <li>Receive the personal data, which you have provided to ARTBIO, in a structured, commonly used, and machine-readable format, and the right to transmit those data to another controller without hindrance from ARTBIO</li>
                        </ul>

                        <p>You may exercise the aforementioned rights by sending a written request to <a href="mailto:GDPR@artbiotx.com">GDPR@artbiotx.com</a>.</p>

                        <p>If you believe that ARTBIO has not complied with applicable data protection laws when processing your personal data, you can lodge a complaint with an applicable supervisory authority.</p>

                        <p className="sub-head"><strong>Changes</strong></p>

                        <p>We may occasionally update this legal and privacy statement. We encourage you to periodically review this legal and privacy statement to stay informed about how we are helping to protect the personal information we collect. Your continued use of our website constitutes your agreement to the terms and conditions for use of our website, our privacy statement, and any updates.</p>
                    </div>
                </div>
            </FluidContent>
        </HcpLayout>
    );
};

export default PrivacyPolicyPage;

export const Head = () => <Seo title="Privacy Policy" />;
